import { ContentResponseModel } from '../model/Content'

async function fetchImage(id: number): Promise<ContentResponseModel> {
  const response = await fetch(process.env.REACT_APP_PROXY_HOST + '/api/content?id=' + id)
  const data = await response.json()
  return { id: data.id, url: data.url }
}

async function fetchImageForSection(id: number) {
  const response = await fetch(process.env.REACT_APP_PROXY_HOST + '/api/section?id=' + id)
  const data = await response.json()
  return { sectionName: data.sectionName, sectionId: data.sectionId, contentList: data.contentList }
}

async function fetchSectionsForSectionType(sectionType: number) {
  const response = await fetch(
    process.env.REACT_APP_PROXY_HOST + '/api/section/list?sectionType=' + sectionType
  )
  const data = await response.json()
  return data
}

export { fetchImage, fetchImageForSection, fetchSectionsForSectionType }
