import { Link } from 'react-router-dom'
import '../../app.css'
import { useEffect, useState } from 'react'
import { Dropdown } from './dropdown/Dropdown'

function Header(): JSX.Element {
  const [width, setWidth] = useState<number>(window.innerWidth)

  const Nav = () => {
    return (
      <nav id='nav-header'>
        <ul>
          <li>
            <Link to='/ilustrations' className='nav-textcolour li-underline' style={style}>
              Ilustrations
            </Link>
          </li>
          {/* <li>
            <Link to='/animations' className='nav-textcolour li-underline' style={style}>
              Animations
            </Link>
          </li> */}
          <li>
            <Link to='/about' className='nav-textcolour li-underline' style={style}>
              About
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
  useEffect(() => {
    function handleResize() {
      setWidth(window.outerWidth)
    }
    window.addEventListener('resize', handleResize)
  })

  const dropdown = () => {
    if (width <= 600) {
      return <Dropdown />
    }
    return <Nav />
  }

  const style = {
    textDecoration: 'none',
  }

  return (
    <>
      <div id='header' className='h-16'>
        <Link to='/' id='main-title' className='nav-textcolour li-underline' style={style}>
          Christinetj.art
        </Link>
        {dropdown()}
      </div>
    </>
  )
}

export default Header
