import { useEffect, useState } from 'react'
import { fetchSectionsForSectionType } from '../api/get/getContent'
import SectionButtons from '../components/section/SectionButtons'
import Section from '../components/section/Section'

function Ilustations(): JSX.Element {
  const [sectionList, setSectionList] = useState<[]>([])
  const [section, setSection] = useState<number>(0)

  const setModel = async () => {
    fetchSectionsForSectionType(1).then((e) => {
      setSectionList(e)
    })
  }

  const sectionComp = () => {
    if (sectionList.length > 0 && sectionList[section]) {
      return (
        <div key={'section-' + section}>
          <Section urls={sectionList[section]['contentList']} />
        </div>
      )
    }
    return <h2>Loading...</h2>
  }

  useEffect(() => {
    setModel()
  }, [])

  console.log(section)

  return (
    <>
      <div className='image-div'>
        <div id='section-titles' className='flex place-content-center'>
          <SectionButtons sectionList={sectionList} setSection={setSection} />
        </div>
        <div>{sectionComp()}</div>
      </div>
    </>
  )
}

export default Ilustations
