async function cacheExists(cacheName : string) {    
  if('caches' in window){
    const cacheNames = await caches.keys()
    return cacheNames.includes(cacheName)
  }
  return false
}

function fileIsImage(cacheString : string | undefined){
  return cacheString?.includes('image/png') || cacheString?.includes('image/jpeg') || cacheString?.includes('image/gif')
}

export {cacheExists, fileIsImage}
