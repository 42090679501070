import { useCallback, useEffect, useState } from 'react'
import DropdownMenu from './DropdownMenu'
import { BsList } from 'react-icons/bs'
import './dropdown.css'

interface DropdownProps {
  isHidden?: boolean
}

function Dropdown({ isHidden = true }: DropdownProps): JSX.Element {
  const [hide, setHide] = useState<boolean>(isHidden)

  const showMenu = useCallback(() => {
    if (hide) return <BsList size={30} className='iconpos' />

    return (
      <>
        <BsList size={30} className='iconpos' />
        <DropdownMenu />
      </>
    )
  }, [hide])

  return (
    <>
      <div onClick={() => setHide(!hide)} className='dropdown'>
        {showMenu()}
      </div>
    </>
  )
}

export { Dropdown }
