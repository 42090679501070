import { useEffect, useState } from 'react'
import axios from 'axios'
import Section from '../components/section/Section'
import Bio from '../components/bio/Bio'
import { fetchSectionsForSectionType } from '../api/get/getContent'
import SectionButtons from '../components/section/SectionButtons'

function About(): JSX.Element {
  const [sectionList, setSectionList] = useState<[]>([])
  const [section, setSection] = useState<number>(0)

  const setModel = async () => {
    fetchSectionsForSectionType(3).then((e) => {
      setSectionList(e)
    })
  }

  const sectionComp = () => {
    if (sectionList.length > 0 && sectionList[section]) {
      return (
        <div key={'section-' + section}>
          <Section urls={sectionList[section]['contentList']} />
        </div>
      )
    }
    return <h2>Loading...</h2>
  }

  useEffect(() => {
    setModel()
  }, [])

  console.log(section)

  return (
    <>
      <div className='flex justify-center'>
        <div>{sectionComp()}</div>
        <Bio />
      </div>
    </>
  )
}

export default About
