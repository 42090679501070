import './dropdown.css'

interface DropdownElementProps {
  link: JSX.Element
}

function DropDownElement({ link }: DropdownElementProps): JSX.Element {
  return (
    <>
      <div id='ddel'>{link}</div>
    </>
  )
}

export { DropDownElement }
