import { useCallback, useEffect, useState } from "react"
import { cacheExists, fileIsImage } from "../../api/utility/SectionUtil"

interface SingleElementProps {
  section : number,
  id : number
}

function SingleElement({section, id} : SingleElementProps){
  const matchUrl = `section/${section}/object/${id}`
  const [element, setElement] = useState<JSX.Element | undefined>(undefined)

  const loadPromise = useCallback(async (promises : Promise<string | undefined>) => {
    const stringPromise = await promises
      if (stringPromise !== undefined){
        const result = (
          fileIsImage(stringPromise) ? <img
            key={id}
            id={`section-${section}`}
            src={stringPromise}
            alt='error'
            className='img-ilus'
          /> : <video key={id} id={`section-${section}`} src={stringPromise} muted={true} autoPlay={true} controls={true} loop={true} className='my-44'/>
        )
        setElement(result)
      }
  }, [id, section])
  const getDataFromCache = useCallback(async () => {
    if(await cacheExists(`section:${section}`)){
      caches
    .open(`section:${section}`)
    .then(async (cache) => {
      const keys = await cache.keys()
      if (keys.length !== 0) {
        const promise = 
           caches
            .match(matchUrl)
            .then((cacheResponse) => {
              return cacheResponse?.text()
            })
            loadPromise(promise)
          }}
        )
      }
  }, [loadPromise, matchUrl, section]) 
  useEffect(() => {
    getDataFromCache()
  },[getDataFromCache])

  return (
    <div>
      {element}
    </div>
  )
} 

export default SingleElement