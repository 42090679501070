import { useEffect } from 'react'
import SingleElement from '../components/single/SingleElement'

function Home(): JSX.Element {
  useEffect(() => {
    document.title = 'Home'
  }, [])

  return (
    <>
      <div>
        <SingleElement section={5} id={11} />
      </div>
    </>
  )
}

export default Home
