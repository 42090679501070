interface FooterProps {
  email?: string
}

function Footer({ email }: FooterProps): JSX.Element {
  return (
    <>
      <div>
        <footer className='footer-m footer-d'>
          <section id='contact' className='footer-container contact-d contact-m'>
            <p>email: {email}</p>
          </section>
          <section id='rights' className='footer-container rights-d rights-m'>
            <p>© All Rights Reserved</p>
          </section>
        </footer>
      </div>
    </>
  )
}

export default Footer
