import { useCallback } from 'react'
import { Content } from '../../api/model/Content'

interface SectionProps {
  urls: Content[]
}

const Section = ({ urls }: SectionProps) => {
  const result = () => {
    if (urls.length === 0) return <h2 className='loading'>Loading...</h2>
    else {
      return urls.map((e, i) => {
        return (
          <img
            key={i}
            id={`section-${e.sectionId}`}
            src={e.url}
            alt='Error'
            className='img-ilus'
          ></img>
        )
      })
    }
  }

  return <div>{result()}</div>
}

export default Section
