function Bio(){
  return (
    <div id="bio" className="mt-12 rounded-md h-min">
      <p>Name: Christine Tjore Lervåg</p>
      <p>Living in Bergen</p>
      <p>Educated at Vold University</p>
    </div>
  )
}

export default Bio