import SectionModel from '../../api/model/Section'

interface SectionButtonsProps {
  sectionList: SectionModel[]
  setSection: React.Dispatch<React.SetStateAction<number>>
}

export default function SectionButtons({ sectionList, setSection }: SectionButtonsProps) {
  const result: JSX.Element[] = []

  if (Array.isArray(sectionList)) {
    sectionList.map((section, i) => {
      result.push(
        <button
          key={'selector-' + section.sectionId}
          id={'selector-' + section.sectionId}
          onClick={() => setSection(i)}
          className='m-2'
        >
          {section.sectionName}
        </button>
      )
    })
  }
  return <div>{result}</div>
}
