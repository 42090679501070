import { Outlet } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

interface LayoutProps {
  email?: string
}

function Layout({ email }: LayoutProps): JSX.Element {
  return (
    <>
      <Header />
      <Outlet />
      <Footer email={email} />
    </>
  )
}

export default Layout
