import { Link } from 'react-router-dom'
import '../../../app.css'
import './dropdown.css'
import { DropDownElement } from './DropdownElement'
import { useCallback, useEffect, useState } from 'react'

function DropdownMenu(): JSX.Element {
  const [dropdownEl, setDropdownEl] = useState<JSX.Element[]>([])

  useEffect(() => {
    const style = {
      textDecoration: 'none',
    }

    const links = [
      <Link id='ilu' to='/ilustrations' className='nav-textcolour' style={style}>
        Ilustrations
      </Link>,
      <Link id='ani' to='/animations' className='nav-textcolour' style={style}>
        Animations
      </Link>,
      <Link id='abo' to='/about' className='nav-textcolour' style={style}>
        About
      </Link>,
    ]

    const dropdownItems = links.map((item, index) => {
      return (
        <>
          <DropDownElement key={'link-' + index} link={item} />
        </>
      )
    })
    setDropdownEl(dropdownItems)
  }, [])

  return (
    <div id='ddmenu'>
      {dropdownEl.map((item) => {
        return item
      })}
    </div>
  )
}

export default DropdownMenu
